






































import Vue from 'vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import EditModellingState from '@/views/projects/modellingState/EditModellingState.vue';
import DeleteModellingState from '@/views/projects/modellingState/DeleteModellingState.vue';
import NewModellingState from '@/views/projects/modellingState/NewModellingState.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';

export default Vue.extend({
  name: 'ListModellingStates',
  components: {
    ListWithFilter,
    NewModellingState,
    DeleteModellingState,
    EditModellingState,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/project/${this.$route.params.projectId}/modelling-states/info`;
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          field: 'description',
          label: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'diagrams',
          label: 'B&ITs',
          field: 'diagrams',
          align: 'center',
          sortable: true,
        },
        {
          name: 'assets',
          label: 'Assets',
          field: 'assets',
          align: 'center',
          sortable: true,
        },
        {
          name: 'connections',
          label: 'Connections',
          field: 'connections',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
});
