









import Vue, { PropType } from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'DeleteModellingState',
  components: { DeleteModal },
  props: {
    modellingState: {
      type: Object as PropType<{ id: string; }>,
      required: true,
    },
  },
  computed: {
    url(): string {
      return `/project/${this.$route.params.projectId}/modelling-states/${this.modellingState.id}`;
    },
  },
  methods: {
    async onDelete() {
      this.$emit('deleted');
    },
  },
});
